import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { EColor, EIcon, ESize, WithThemeProps } from '@core/type';

import { modalClosingAnimation, modalOpeningAnimation } from '../animation';
import { ButtonIcon } from '../button/ButtonIcon';
import { IFrameYoutube } from '../iframe';
import { Box } from '../layout';
import { ModalBase } from './ModalBase';
import {
  ModalContentWrapperProps,
  ModalVideoProps,
  StyledContentWrapperProps,
  WrapperProps,
} from './interface-modal';

const ContentWrapperBase = forwardRef<HTMLDivElement, ModalContentWrapperProps>(
  ({ children, ...props }: ModalContentWrapperProps, ref) => (
    <Box borderRadius="8px" margin="24px 0" width="100%" {...props} ref={ref}>
      {children}
    </Box>
  ),
);

const HeightWrapper = styled.div`
  ${(props: WithThemeProps) => {
    const {
      theme: {
        structure: {
          videoModalContainer: { height },
        },
      },
    } = props;

    const rules = {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      justifyContent: 'center',
      maxWidth: '950px',
      margin: '0 auto',
      height,
    } as const;

    return css`
      ${rules};
    `;
  }}
`;

const IconBox = styled(Box)`
  ${(props: WithThemeProps) => {
    const {
      theme: {
        structure: {
          videoModalContainer: { iconBox },
        },
      },
    } = props;

    return css`
      ${iconBox};
    `;
  }}
`;

ContentWrapperBase.displayName = 'ContentWrapperBase';

const StyledContentWrapper = styled(ContentWrapperBase)`
  max-height: 548px;
  animation: ${({ isClosing }: StyledContentWrapperProps) =>
    isClosing
      ? css`
          ${modalClosingAnimation} 0.3s ease-out
        `
      : css`
          ${modalOpeningAnimation} 0.3s ease-out
        `};
`;

const Wrapper = forwardRef<HTMLDivElement, WrapperProps>(
  ({ size, isClosing, onCloseModal, children }: WrapperProps, ref) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100%"
      margin="0 auto"
      height="100%"
    >
      <HeightWrapper>
        <IconBox position="absolute" cursor="pointer">
          <ButtonIcon
            padding="3px"
            onClick={onCloseModal}
            iconProps={{
              type: EIcon.CROSS,
              color: { commonType: EColor.WHITE, intensity: EColor.R900 },
              strokeWidth: 1.5,
              size: ESize.XS,
            }}
          />
        </IconBox>
        <StyledContentWrapper size={size} isClosing={isClosing} ref={ref}>
          {children}
        </StyledContentWrapper>
      </HeightWrapper>
    </Box>
  ),
);
Wrapper.displayName = 'Wrapper';

export const ModalVideo = ({
  isOpen,
  onCloseModal,
  videoLink,
  title,
  language,
}: ModalVideoProps) => (
  <ModalBase isOpen={isOpen} onCloseModal={onCloseModal} styledContentWrapper={Wrapper}>
    <IFrameYoutube
      title={title}
      language={language}
      src={videoLink}
      boxProps={{
        maxHeight: '548px',
        height: 'min(56.25vw, 100vh - 80px)',
        width: '100%',
      }}
    />
  </ModalBase>
);
