import { FC, MouseEventHandler, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import {
  BackgroundColorType,
  ColorProps,
  EActionAttribute,
  EColor,
  EFontWeight,
  ThemeProps,
} from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Box } from '../layout/Box';
import { TabProps } from './interface-tabs';
import { setOnChangeTabEvent } from './tab-event';

export const Tab = <T extends string | number>({
  children,
  value,
  display = 'block',
  onChange,
  isActive,
  isFullwidth,
  isTabVisible = true,
  lineActiveColor,
  backgroundActiveColor,
  hasBoxShadow = true,
}: TabProps<T>) => {
  const handleOnChange: MouseEventHandler<HTMLDivElement> = (e) => {
    !isActive && onChange(setOnChangeTabEvent(e, value));
  };

  if (isTabVisible) {
    return (
      <Box
        as={EActionAttribute.LI}
        listStyleType={'none'}
        width={isFullwidth ? '100%' : 'auto'}
        height={'64px'}
        overflow={'hidden'}
        display={display}
        fontWeight={EFontWeight.BOLD}
        boxShadow={isActive && hasBoxShadow ? EColor.ELEVATION_4 : undefined}
        role={'tab'}
        aria-selected={isActive}
      >
        <StyledTab
          isActive={isActive}
          onClick={handleOnChange}
          lineActiveColor={lineActiveColor}
          backgroundActiveColor={backgroundActiveColor}
        >
          {children}
        </StyledTab>
      </Box>
    );
  }

  return null;
};

const StyledTab: FC<{
  isActive: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  children: ReactNode;
  lineActiveColor?: ColorProps<BackgroundColorType>;
  backgroundActiveColor?: ColorProps<BackgroundColorType>;
}> = styled.div<{ isActive: boolean }>(
  ({
    theme: {
      palette,
      structure: {
        tab: { topBorder: topBorderProps, ...tabProps },
      },
    },
    isActive,
    lineActiveColor = EColor.ACCENT,
    backgroundActiveColor = { semanticType: EColor.NEUTRAL, variant: EColor.LIGHT },
  }: {
    isActive: boolean;
    theme: ThemeProps;
    lineActiveColor?: ColorProps<BackgroundColorType>;
    backgroundActiveColor?: ColorProps<BackgroundColorType>;
  }) => {
    const { getTextColor, getBackgroundColor } = getPaletteHandlers(palette);
    const lineActiveBackgroundColor = getBackgroundColor(lineActiveColor);
    const textActiveColor = getTextColor(EColor.ACCENT);

    const activeStyles = {
      color: textActiveColor,
      backgroundColor: getBackgroundColor(backgroundActiveColor),
      '&::before': {
        ...topBorderProps,
        backgroundColor: lineActiveBackgroundColor,
      },
    };

    const inactiveStyles = {
      color: getTextColor(EColor.PRIMARY),
      backgroundColor: getBackgroundColor(EColor.TRANSPARENT),
      '&:hover': {
        color: textActiveColor,
      },
    };

    return {
      ...tabProps,
      ...(isActive ? activeStyles : inactiveStyles),
    } as CSSObject;
  },
);
