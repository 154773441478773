import useTranslation from 'next-translate/useTranslation';

import { Translate } from '@core/constant';
import { EColor, EIcon, ELinkAnimation, EPosition, ERouting } from '@core/type';

import { LinkTranslatedStyledAsLink } from '../link-translated';

export const LinkToFaqPage = () => {
  const { t } = useTranslation(Translate.common.LINK);

  return (
    <LinkTranslatedStyledAsLink
      keyRoute={ERouting.FAQ}
      color={EColor.ACCENT}
      endIcon={EIcon.ARROW_DOWN}
      iconPosition={EPosition.RIGHT}
      iconColor={EColor.SECONDARY}
      animation={ELinkAnimation.ARROW_RIGHT}
      iconOrientation={EPosition.RIGHT}
      isIconStroke
    >
      {t('moreFaq.title')}
    </LinkTranslatedStyledAsLink>
  );
};
