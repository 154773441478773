import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Translate } from '@core/constant';
import { usePrevious } from '@core/hook';
import { EColor, EIcon, ETypographyVariant, EZIndexName } from '@core/type';

import { copiedCollapsingAnimation, copiedExpandingAnimation } from '../animation';
import { Icon } from '../icon';
import { Box } from '../layout';
import { Typography } from '../typography';
import type { CopiedDivProps, CopyToClipboardProps } from './interface-copy';

const HoveredIcon = styled(Icon)`
  vertical-align: middle;
`;

const Wrapper = styled.span(
  ({ width }: { width: string }) => `
  position: relative;
  display: inline-block;
  width: ${width};

  &:hover {
    cursor: pointer;
  }
`,
);

export const CopiedDiv = styled.div<CopiedDivProps>`
  ${(props: CopiedDivProps) => {
    const { hasCopied, enableAnimation } = props;

    return css`
      animation: ${hasCopied
          ? enableAnimation && copiedExpandingAnimation
          : enableAnimation && copiedCollapsingAnimation}
        0.2s ease-out;

      top: ${hasCopied ? '-35px' : '-10px'};
      height: ${hasCopied ? 'initial' : '0px'};
      left: 50%;

      z-index: ${EZIndexName.TOOLTIP};
      position: absolute;
      transform: translateX(-50%);
      overflow: hidden;
    `;
  }}
`;

export const CopyToClipboard = ({
  children,
  sizeIcon,
  colorIcon,
  justifyContent,
  hasIcon = true,
  valueToCopy,
  width,
}: CopyToClipboardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(Translate.common.COMMON);

  const [hasCopied, setHasCopied] = useState(false);
  const [enableAnimation, setEnableAnimation] = useState(false);

  const hasPreviouslyCopied = usePrevious(hasCopied);

  useEffect(() => {
    if ((hasCopied && !hasPreviouslyCopied) || (!hasCopied && hasPreviouslyCopied)) {
      setEnableAnimation(true);
    }
  }, [hasCopied, hasPreviouslyCopied]);

  const onClick = async () => {
    const innerHtml = ref.current.innerHTML;
    const text = valueToCopy ?? ref.current.innerText;

    /* Firefox still doesn't implement ClipboardItem (all other browsers do),¨
     so let's just copy text on Firefox https://caniuse.com/mdn-api_clipboarditem
     */
    if (window.ClipboardItem) {
      const data = [
        new ClipboardItem({
          'text/plain': new Blob([text], { type: 'text/plain' }),
          'text/html': new Blob([innerHtml], { type: 'text/html' }),
        }),
      ];

      await navigator.clipboard.write(data).then(() => {
        setHasCopied(true);
      });
    } else {
      await navigator.clipboard.writeText(text).then(() => {
        setHasCopied(true);
      });
    }
  };

  return (
    <Wrapper
      onClick={() => {
        void onClick();
      }}
      onMouseLeave={() => {
        setHasCopied(false);
      }}
      width={width}
    >
      <Box display="flex" alignItems="center" justifyContent={justifyContent}>
        <Box ref={ref} width={width}>
          {children}
        </Box>

        {hasIcon && (
          <HoveredIcon
            type={EIcon.COPY}
            color={colorIcon ?? EColor.ACCENT}
            display="inline-block"
            marginLeft="5px"
            size={sizeIcon}
          />
        )}
      </Box>
      <CopiedDiv hasCopied={hasCopied} enableAnimation={enableAnimation}>
        <Box
          borderRadius="8px"
          padding="6px 10px"
          borderWidth={'1px'}
          borderColor={{ commonType: EColor.GREEN, intensity: EColor.R100 }}
          backgroundColor={{ semanticType: EColor.SUCCESS, variant: EColor.LIGHT }}
        >
          <Typography color={EColor.SUCCESS} variant={ETypographyVariant.H6}>
            {t('application.copied')}
          </Typography>
        </Box>
      </CopiedDiv>
    </Wrapper>
  );
};
